<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-img
          v-if="optionsLocal.member.avatar"
          ref="previewEl"
          rounded
          :src="optionsLocal.member.avatar"
          height="80"
        />
        <b-img
          v-else
          ref="previewEl"
          rounded
          :src="avatar"
          width="125"
        />
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="optionsLocal.File"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="onFileChange"
        />
        <!--/ upload button -->

        <!-- reset -->
        <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button> -->
        <!--/ reset -->
        <b-card-text>Foto JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Nama Lengkap"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.name"
              name="name"
              placeholder="Nama Lengkap"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="example-datepicker"
            label="Usia"
          >
            <b-form-input
              v-model="optionsLocal.member.age"
              placeholder="Usia"
              disabled="disabled"
            />
            <!-- <flat-pickr
              v-model="optionsLocal.member.tanggal_lahir"
              class="form-control"
              name="date"
              placeholder="Tanggal Lahir"
            /> -->
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="optionsLocal.email"
              name="email"
              placeholder="Email"
              :readonly="true"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="genderList"
            label="Jenis Kelamin"
          >
            <v-select
              id="genderList"
              v-model="optionsLocal.member.gender"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="genderOption.options"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="genderList"
            label="Ragam Disabilitas"
          >
            <v-select
              id="genderList"
              v-model="optionsLocal.member.disabilitas"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="disabilitasOption.options"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col sm="6">
          <b-form-group
            label="No KTP / Passport"
            label-for="account-company"
          >
            <b-form-input
              v-model="optionsLocal.company"
              name="company"
              placeholder="No KTP / Passport"
            />
          </b-form-group>
        </b-col> -->

        <!-- alert -->
        <!-- <b-col
          cols="12"
          class="mt-75"
        >
          <b-alert
            show
            variant="warning"
            class="mb-50"
          >
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link">
                Resend confirmation
              </b-link>
            </div>
          </b-alert>
        </b-col> -->
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="simpan"
          >
            Simpan
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button> //-->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'
import {
  getJenisKelamin,
  getDisabilitas,
} from '@/connection-api/master'
import flatPickr from 'vue-flatpickr-component'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    // BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    // BLink,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  // props: {
  //   generalData: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  data() {
    return {
      genderOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Kelamin --' },
        ],
      },
      disabilitasOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Disabilitas --' },
        ],
      },
      optionsLocal: {},
      profileFile: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      userId: null,
      /* eslint-disable global-require */
      avatar: require('@/assets/images/avatars/default.png'),
      /* eslint-disable global-require */
    }
  },
  unmounted() {
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  },
  async mounted() {
    const responseJenisKelamin = await getJenisKelamin()
    this.mappingSelectOption(responseJenisKelamin.data, this.genderOption.options)

    const responseJenisDisabilitas = await getDisabilitas()
    this.mappingSelectOption(responseJenisDisabilitas.data, this.disabilitasOption.options)

    if (this.$route.params.id) {
      this.userId = this.$route.params.id
    } else {
      this.userId = this.userData.id
    }
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    store.dispatch('pengguna/fetchData', { id: this.userId })
      .then(response => {
        this.optionsLocal = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.optionsLocal = {}
        }
      })
  },
  methods: {
    simpan() {
      this.localData = this.optionsLocal
      if (!this.localData.member) {
        this.localData.member = {}
      }
      this.localData.member.gender_id = (this.localData.member.gender == null) ? null : this.localData.member.gender.value
      this.localData.member.disabilitas_id = (this.localData.member.disabilitas == null) ? null : this.localData.member.disabilitas.value
      this.$emit('clicked', this.localData)
    },
    resetForm() {
      this.optionsLocal = {}
    },
    onFileChange(e) {
      const file = e// .target.files[0]
      this.optionsLocal.member.avatar = URL.createObjectURL(file)
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
