<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Umum</span>
      </template>

      <account-setting-general
        @clicked="simpanData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Informasi</span>
      </template>

      <account-setting-information
        @clicked="simpanDataInformasi"
      />
    </b-tab>

    <!-- ubah password -->
    <b-tab
      v-if="$can('admin-change-password', 'user')"
    >
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Ubah Password </span>
      </template>

      <account-setting-password
        :adminProcess="true"
      />
    </b-tab>

    <b-tab
      v-if="$can('change-rules', 'user')"
    >
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Hak Akses </span>
      </template>

      <rules
        :adminProcess="true"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import {
  insUser,
  ubahUser,
  uploadFileUser,
} from '@/api/user'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import Rules from './Rules.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingInformation,
    AccountSettingPassword,
    Rules,
    // AccountSettingSocial,
    // AccountSettingNotification,
  },
  data() {
    return {
      options: {},
      permissionChangePassword: false,
    }
  },
  beforeCreate() {
    if (this.$can('admin-change-password', 'user')) {
      this.permissionChangePassword = true
    } else {
      this.permissionChangePassword = false
    }
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
  methods: {
    async simpanDataSocial(value) {
      // console
      value.form = 'social'
      if (value.id) {
        const response = await ubahUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah Informasi Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
        // this.makeToast('danger', error.response.data)
        // this.show = false
      }
    },
    async simpanDataInformasi(value) {
      // console
      value.form = 'informasi'
      if (value.id) {
        const response = await ubahUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah Informasi Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
        // this.makeToast('danger', error.response.data)
        // this.show = false
      }
    },
    async simpanData(value) {
      let imagefilename = ''
      if (value.File) {
        // Update File
        const responseUpload = await uploadFileUser(value.File, 'user', event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        imagefilename = responseUpload.data.filename
      }
      if (value.id) {
        if (value.File) {
          value.img = imagefilename
          delete value.File
        }
        const response = await ubahUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah User Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
      } else {
        if (value.File) {
          value.img = imagefilename
          delete value.File
        }
        // console.log(value)
        const response = await insUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Tambah User Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
      }
    },
    makeToast(variant = null, message = null, title = null) {
      this.$bvToast.toast(message, {
        title: 'Kegiatan',
        variant,
        solid: true,
        autoHideDelay: 10000,
        enableHtml: true,
        allowHtml: true,
      })
    },
  },
}
</script>
