<template>
  <b-card>
    <b-row class="blog-list-wrapper mt-1">
      <b-col>
        <b-card-text><h4>Hak Akses</h4></b-card-text>
        <span
          v-for="item in optionsLocal.roles"
          :key="item.id"
          md="5"
        >
          <b-button
            v-if="item.active"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mt-1 mr-1"
            @click="hapusRoles(item.guard_name)"
          >
            <feather-icon icon="CheckIcon" />
            {{item.name}}
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-1 mr-1"
            @click="pilihRoles(item.guard_name)"
          >
            {{item.name}}
          </b-button>
        </span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      optionsLocal: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      userId: null,
    }
  },
  unmounted() {
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  },
  async mounted() {
    if (this.$route.params.id) {
      this.userId = this.$route.params.id
    } else {
      this.userId = this.userData.id
    }
    this.reloadData()
  },
  methods: {
    reloadData() {
      const USER_APP_STORE_MODULE_NAME = 'pengguna'
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

      store.dispatch('pengguna/fetchDataRoles', { id: this.userId })
        .then(response => {
          this.optionsLocal = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.optionsLocal = {}
          }
        })
    },
    pilihRoles(guardName) {
      const localdata = {
        user_id: this.userId,
        rules: guardName,
      }
      store.dispatch('pengguna/addDataRoles', localdata)
        .then(response => {
          this.reloadData()
        })
        .catch(error => {
          if (error.response.status === 404) {
            // console
          }
        })
    },
    hapusRoles(guardName) {
      const localdata = {
        user_id: this.userId,
        rules: guardName,
      }
      store.dispatch('pengguna/removeDataRoles', localdata)
        .then(response => {
          this.reloadData()
        })
        .catch(error => {
          if (error.response.status === 404) {
            // console
          }
        })
    },
  },
  beforeCreate() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
