<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <b-col sm="12">
          <b-form-group
            label="Alamat"
            label-for="account-alamat"
          >
            <b-form-input
              v-model="optionsLocal.member.alamat"
              name="alamat"
              placeholder="Alamat"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="provinsiList"
            label="Provinsi"
          >
            <v-select
              id="provinsiList"
              v-model="optionsLocal.member.provinsi"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="provinsiOption.options"
              @input="(val) => changeKabKota(val)"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="kabKotaList"
            label="Kabupaten / Kota"
          >
            <v-select
              id="kabKotaList"
              v-model="optionsLocal.member.kabkota"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="kabkotaOption.options"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="phone"
            label="HP / Telepon"
          >
            <cleave
              id="phone"
              v-model="optionsLocal.member.nomor_hp"
              class="form-control"
              :raw="false"
              :options="clevePhone"
              placeholder="HP / Telepon"
            />
          </b-form-group>
        </b-col>
        <!-- phone -->

        <b-col md="6">
          <b-form-group
            label-for="pendidikanList"
            label="Pendidikan Terakhir"
          >
            <v-select
              id="pendidikanList"
              v-model="optionsLocal.member.pendidikan_terakhir"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pendidikanOption.options"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="statusPernikahanList"
            label="Status Pernikahan"
          >
            <v-select
              id="statusPernikahanList"
              v-model="optionsLocal.member.status_pernikahan"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusPernikahanOption.options"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="statusPekerjaanList"
            label="Status Pekerjaan"
          >
            <v-select
              id="statusPekerjaanList"
              v-model="optionsLocal.member.status_pekerjaan"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusPekerjaanOption.options"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Facebook"
            label-for="account-facebook"
          >
            <b-form-input
              v-model="optionsLocal.member.facebook"
              name="facebook"
              placeholder="https://www.facebook.com/"
            />

          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Linkedin"
            label-for="account-linkedin"
          >
            <b-form-input
              v-model="optionsLocal.member.linkedin"
              name="linkedin"
              placeholder="https://www.linkedin.com/"
            />

          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Telegram"
            label-for="account-telegram"
          >
            <b-form-input
              v-model="optionsLocal.member.telegram"
              name="telegram"
              placeholder="Username Telegram"
            />

          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Instagram"
            label-for="account-instagram"
          >
            <b-form-input
              v-model="optionsLocal.member.instagram"
              name="instagram"
              placeholder="Username instagram"
            />

          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Youtube Channel"
            label-for="account-youtube"
          >
            <b-form-input
              v-model="optionsLocal.member.youtube"
              name="youtube"
              placeholder="https://www.youtube.com/c/channelid"
            />

          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="simpan"
          >
            Simpan
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'
import {
  getPropinsi,
  getKabKota,
  getJenisPendidikan,
  getStatusPekerjaan,
  getStatusPernikahan,
} from '@/connection-api/master'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      provinsiOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Propinsi --' },
        ],
      },
      kabkotaOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kabupaten / Kota --' },
        ],
      },
      pendidikanOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Pendidikan Terakhir --' },
        ],
      },
      statusPekerjaanOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Status Pekerjaan --' },
        ],
      },
      statusPernikahanOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Status Pernikahan --' },
        ],
      },
      // desaOption: ['Desa Parakan Jaya', 'Desa Cimanggu'],
      optionsLocal: {},
      clevePhone: {
        phone: true,
        phoneRegionCode: 'ID',
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async mounted() {
    const responsePropinsi = await getPropinsi()
    this.mappingSelectOption(responsePropinsi.data, this.provinsiOption.options)

    const responseJenisPendidikan = await getJenisPendidikan()
    this.mappingSelectOption(responseJenisPendidikan.data, this.pendidikanOption.options)

    const responseStatusPekerjaan = await getStatusPekerjaan()
    this.mappingSelectOption(responseStatusPekerjaan.data, this.statusPekerjaanOption.options)

    const responseStatusPernikahan = await getStatusPernikahan()
    this.mappingSelectOption(responseStatusPernikahan.data, this.statusPernikahanOption.options)

    if (this.$route.params.id) {
      this.userId = this.$route.params.id
    } else {
      this.userId = this.userData.id
    }
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    store.dispatch('pengguna/fetchData', { id: this.userId })
      .then(response => {
        this.optionsLocal = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.optionsLocal = {}
        }
      })
  },
  methods: {
    async changeKabKota(propinsiId) {
      this.kabkotaOption = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kabupaten Kota --' },
        ],
      }
      const response = await getKabKota(propinsiId.value)
      this.mappingSelectOption(response.data, this.kabkotaOption.options)
    },
    simpan() {
      this.localData = this.optionsLocal
      if (!this.localData.member) {
        this.localData.member = {}
      }
      this.localData.member.provinsi_id = (this.localData.member.provinsi == null) ? null : this.localData.member.provinsi.value
      this.localData.member.kabkota_id = (this.localData.member.kabkota == null) ? null : this.localData.member.kabkota.value
      this.localData.member.pendidikan_terakhir_id = (this.localData.member.pendidikan_terakhir == null) ? null : this.localData.member.pendidikan_terakhir.value
      this.localData.member.status_pekerjaan_id = (this.localData.member.status_pekerjaan == null) ? null : this.localData.member.status_pekerjaan.value
      this.localData.member.status_pernikahan_id = (this.localData.member.status_pernikahan == null) ? null : this.localData.member.status_pernikahan.value
      this.$emit('clicked', this.localData)
    },
    resetForm() {
      this.optionsLocal = {}
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
